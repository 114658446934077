import React from "react"
import styled from "styled-components"
import { useStaticQuery, Link, graphql } from "gatsby"
import "@fontsource/montserrat"
import "./page-layout.css"

const PageContainer = styled.div`
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 650px;
  min-height: calc(100vh - 2rem);
  padding: 1rem;

  @media (max-width: 540px) {
    padding-top: 0.3rem;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderLeft = styled.div``

const HeaderRight = styled.div``

const SiteTitle = styled.h3``

const StyledLink = styled(Link)`
  text-decoration: none;
`

const TwitterLink = styled.a``

const AboutLink = styled(Link)`
  padding-left: 2rem;
  text-decoration: none;
`

const Body = styled.div``

const Footer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 1rem;
`

const FooterText = styled.div`
  text-align: center;
`

export default function PageLayout({ children, showAffiliateLink }) {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <PageContainer>
      <Header>
        <HeaderLeft>
          <StyledLink to={`/`}>
            <SiteTitle>
              {data.site.siteMetadata.title}
            </SiteTitle>
          </StyledLink>
        </HeaderLeft>
        <HeaderRight>
          <TwitterLink href="https://twitter.com/dailydefiorg">
            Twitter
          </TwitterLink>
          <AboutLink to={`/about/`}>
            About
          </AboutLink>
        </HeaderRight>
      </Header>
      <Body>
        {children}
      </Body>
      <Footer>
        {
          showAffiliateLink &&
          <FooterText>
            Sponsored Book: <a 
              target="_blank"
              rel="noreferrer"
              href="https://www.amazon.com/gp/product/1491971940/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1491971940&linkCode=as2&tag=dealgira-20&linkId=d86261d7a50e1c571e2a9e916700d132">
              Mastering Ethereum: Building Smart Contracts and DApps
            </a>
          </FooterText>
        }
      </Footer>
    </PageContainer>
  )
}