import React, { useState } from "react"
import PageLayout from "../../components/page-layout"
import SEO from "../../components/seo"
import styled from "styled-components"

const Header = styled.h1`
  @media (max-width: 540px) {
    margin-top: 0.5rem;
  }
`

const HeaderInfo = styled.div`
  padding-bottom: 0.25rem;
`

const HeaderInfoRow = styled.p``

const Section = styled.div``

const SectionTitle = styled.h3``

const InputRow = styled.div`
  padding-bottom: 1rem;
`

const InputLabel = styled.span`
  padding-right: 12px;

  &::after {
    content: '-';
    padding-left: 10px;
  }
`

const InputBox = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  display: inline-block;
  padding: 0.2rem;
  padding-left: 0.5rem;
`

const Input = styled.input`
  padding: .3rem;
  padding-left: 0.2rem;
  border: none;
  width: 80px;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
`

const Results = styled.div``

const calcAPoolSize = (aPrice, constantProduct) => {
  return Math.sqrt(constantProduct / aPrice)
}

const calcBPoolSize = (aPrice, constantProduct) => {
  return Math.sqrt(constantProduct * aPrice)
}

const calculcateImpermanentLoss = (priceInitialA, priceInitialB, priceFutureA, priceFutureB) => {
  const priceAInBInitial = priceInitialA / priceInitialB
  const tokenQtyStartA = 1
  const tokenQtyStartB = tokenQtyStartA * priceAInBInitial
  const constantProduct = tokenQtyStartA * tokenQtyStartB

  const priceAInBFinal = priceFutureA / priceFutureB
  const tokenQtyFutureA = calcAPoolSize(priceAInBFinal, constantProduct)
  const tokenQtyFutureB = calcBPoolSize(priceAInBFinal, constantProduct)

  const valueIfHeld = (tokenQtyStartA * priceFutureA) + (tokenQtyStartB * priceFutureB)
  const valueIfProvidedLiquidity = (tokenQtyFutureA * priceFutureA) + (tokenQtyFutureB * priceFutureB)
  const pctRemaining = (valueIfProvidedLiquidity / valueIfHeld) * 100
  const impermanentLoss = (pctRemaining - 100) * -1

  return {
    impermanentLoss,
    tokenQtyFutureA,
    tokenQtyFutureB
  }
}

const formatNumber = (num, decimalPlaces) => {
  return numberWithCommas(num.toFixed(decimalPlaces))
}

const isValidInput = (priceInitialA, priceInitialB, priceFutureA, priceFutureB) => {
  return (priceInitialA && priceInitialB && priceFutureA && priceFutureB)
    && (priceInitialA > 0 && priceInitialB > 0 && priceFutureA > 0&& priceFutureB > 0)
}

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export default function ImpermanentLossCalculator() {
  const [priceInitialA, setPriceInitialA] = useState(0)
  const [priceInitialB, setPriceInitialB] = useState(0)
  const [priceFutureA, setPriceFutureA] = useState(0)
  const [priceFutureB, setPriceFutureB] = useState(0)

  const impermanentLossRes = isValidInput(priceInitialA, priceInitialB, priceFutureA, priceFutureB)
    ? calculcateImpermanentLoss(priceInitialA, priceInitialB, priceFutureA, priceFutureB)
    : null

  const renderResults = ({impermanentLoss, tokenQtyFutureA, tokenQtyFutureB}) => {
    const qtyTokenAInitialExample = 500 / priceInitialA
    const qtyTokenBInitialExample = 500 / priceInitialB
    const qtyTokenAFutureExample = tokenQtyFutureA * qtyTokenAInitialExample
    const qtyTokenBFutureExample = tokenQtyFutureB * qtyTokenAInitialExample

    const tokenAHoldWorth = qtyTokenAInitialExample * priceFutureA
    const tokenBHoldWorth = qtyTokenBInitialExample * priceFutureB
    const totalHoldWorth = tokenAHoldWorth + tokenBHoldWorth

    const tokenALiquidtyWorth = qtyTokenAFutureExample * priceFutureA
    const tokenBLiquidityWorth = qtyTokenBFutureExample * priceFutureB
    const totalLiquidityWorth = tokenALiquidtyWorth + tokenBLiquidityWorth

    return (
      <Results>
        <p>{`Impermanent loss: ${formatNumber(impermanentLoss, 2)}%`}</p>
        <hr/>

        <p>If $500 of Token A and $500 of Token B were held</p>
        <p>{`- Have ${formatNumber(qtyTokenAInitialExample, 2)} Token A and ${formatNumber(qtyTokenBInitialExample, 2)} Token B`}</p>
        <p>{`- Value if held: $${formatNumber(totalHoldWorth, 2)}`}</p>
        <hr/>

        <p>If $500 of Token A and $500 of Token B were provided as liquidity</p>
        <p>{`- Have ${formatNumber(qtyTokenAFutureExample, 2)} Token A and ${formatNumber(qtyTokenBFutureExample, 2)} Token B (in liquidity pool)`}</p>
        <p>{`- Value if providing liquidity: $${formatNumber(totalLiquidityWorth, 2)}`}</p>
      </Results>
    )
  }

  return (
    <PageLayout showAffiliateLink>
      <SEO
        title="Impermanent Loss Calculator"
        description="Impermanent loss calculator for liquidity providers on Uniswap or other decentralized exchanges."
      />
      <Header>
        Impermanent Loss Calculator
      </Header>
      <HeaderInfo>
        <HeaderInfoRow>
          This calculator uses
          Uniswap's <a href="https://uniswap.org/docs/v2/protocol-overview/glossary/#x--y--k">constant product formula</a> to
          determine impermanent loss.
        </HeaderInfoRow>
        <HeaderInfoRow>
          Fees are not included within results.
        </HeaderInfoRow>
      </HeaderInfo>
      <Section>
        <SectionTitle>
          Initial Prices
        </SectionTitle>
        <InputRow>
          <InputLabel>Token A</InputLabel>
          <InputBox>
          $<Input 
            value={priceInitialA}
            onChange={e => setPriceInitialA(e.target.value)}
            type="number"
            min="0"
          />
          </InputBox>
        </InputRow>
        <InputRow>
          <InputLabel>Token B</InputLabel>
          <InputBox>
          $<Input 
            value={priceInitialB}
            onChange={e => setPriceInitialB(e.target.value)}
            type="number"
            min="0"
          />
          </InputBox>
        </InputRow>
      </Section>
      <Section>
        <SectionTitle>
          Future Prices
        </SectionTitle>
        <InputRow>
          <InputLabel>Token A</InputLabel>
          <InputBox>
          $<Input 
            value={priceFutureA}
            onChange={e => setPriceFutureA(e.target.value)}
            type="number"
            min="0"
          />
          </InputBox>
        </InputRow>
        <InputRow>
          <InputLabel>Token B</InputLabel>
          <InputBox>
          $<Input 
            value={priceFutureB}
            onChange={e => setPriceFutureB(e.target.value)}
            type="number"
            min="0"
          />
          </InputBox>
        </InputRow>
      </Section>
      <Section>
        <SectionTitle>
          Results
        </SectionTitle>
        {
          impermanentLossRes
          ? renderResults(impermanentLossRes)
          : 'Enter valid prices to see results'
        }
      </Section>
    </PageLayout>
  )
}